import { supabase } from '../../supabaseClient';

export const FETCH_REWARDS = 'FETCH_REWARDS';
export const UPDATE_REWARDS = 'UPDATE_REWARDS';
export const ADD_REWARD_POINTS = 'ADD_REWARD_POINTS';
export const UPDATE_BADGE = 'UPDATE_BADGE';

export const fetchRewards = (userId) => async (dispatch) => {
  try {

    if (!userId) {
      console.error('fetchRewards called without userId');
      return;
    }

    const { data, error } = await supabase
      .from('user_rewards')
      .select('*')
      .eq('user_id', userId)
      .maybeSingle();

    if (error) {
      console.error('Error fetching rewards:', error);
      throw error;
    }


    if (!data) {
      const { data: newData, error: insertError } = await supabase
        .from('user_rewards')
        .insert([
          {
            user_id: userId,
            points: 0,
            badges: [],
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          }
        ])
        .select()
        .single();

      if (insertError) {
        throw insertError;
      }
      
      dispatch({
        type: FETCH_REWARDS,
        payload: newData,
      });
    } else {
      dispatch({
        type: FETCH_REWARDS,
        payload: data,
      });
    }
  } catch (error) {
  }
};

export const updateRewards = (userId, points, badges) => async (dispatch) => {
  try {
    const { data: userData, error: userError } = await supabase
      .from('user_rewards')
      .upsert({
        user_id: userId,
        points,
        badges,
        updated_at: new Date().toISOString()
      })
      .select()
      .single();

    if (userError) throw userError;

    const { error: historyError } = await supabase
      .from('rewards_history')
      .insert([{
        user_id: userId,
        points: points - (userData?.points || 0),
        action: 'update_rewards',
        description: 'Updated user rewards',
        timestamp: new Date().toISOString()
      }]);

    if (historyError) throw historyError;

    await dispatch(fetchRewards(userId));

    dispatch({
      type: UPDATE_REWARDS,
      payload: userData,
    });
  } catch (error) {
    console.error('Error updating rewards:', error);
    throw error;
  }
};

export const addRewardPoints = (userId, points, action, description) => async (dispatch) => {
  try {
    const { error: historyError } = await supabase
      .from('rewards_history')
      .insert([{
        user_id: userId,
        points,
        action,
        description,
        timestamp: new Date().toISOString()
      }]);

    if (historyError) throw historyError;

    const { data: userData, error: userError } = await supabase
      .from('user_rewards')
      .select('points')
      .eq('user_id', userId)
      .single();

    if (userError) throw userError;

    const { data: updatedData, error: updateError } = await supabase
      .from('user_rewards')
      .update({ 
        points: (userData?.points || 0) + points,
        updated_at: new Date().toISOString()
      })
      .eq('user_id', userId)
      .select()
      .single();

    if (updateError) throw updateError;

    await dispatch(fetchRewards(userId));

    dispatch({
      type: ADD_REWARD_POINTS,
      payload: points,
    });
  } catch (error) {
    console.error('Error adding reward points:', error);
    throw error;
  }
};

export const updateBadge = (badge) => ({
  type: UPDATE_BADGE,
  payload: badge,
});
