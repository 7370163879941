const initialState = {
  settings: {},
  loading: false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_USER_SETTINGS_REQUEST':
    case 'GET_USER_SETTINGS_REQUEST':
      return { ...state, loading: true, error: null };
    case 'UPDATE_USER_SETTINGS_SUCCESS':
    case 'GET_USER_SETTINGS_SUCCESS':
      return { ...state, settings: action.payload, loading: false, error: null };
    case 'UPDATE_USER_SETTINGS_FAILURE':
    case 'GET_USER_SETTINGS_FAILURE':
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default userReducer;