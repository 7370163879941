import {
  FETCH_CLAIMS_REQUEST,
  FETCH_CLAIMS_SUCCESS,
  FETCH_CLAIMS_FAILURE,
  SAVE_DRAFT_CLAIM_REQUEST,
  SAVE_DRAFT_CLAIM_SUCCESS,
  SAVE_DRAFT_CLAIM_FAILURE,
  UPDATE_DRAFT_CLAIM_REQUEST,
  UPDATE_DRAFT_CLAIM_SUCCESS,
  UPDATE_DRAFT_CLAIM_FAILURE,
  DELETE_DRAFT_CLAIM_REQUEST,
  DELETE_DRAFT_CLAIM_SUCCESS,
  DELETE_DRAFT_CLAIM_FAILURE,
  SUBMIT_CLAIM_REQUEST,
  SUBMIT_CLAIM_SUCCESS,
  SUBMIT_CLAIM_FAILURE,
  GET_AI_INSIGHTS_REQUEST,
  GET_AI_INSIGHTS_SUCCESS,
  GET_AI_INSIGHTS_FAILURE,
  ANALYZE_DOCUMENT_REQUEST,
  ANALYZE_DOCUMENT_SUCCESS,
  ANALYZE_DOCUMENT_FAILURE,
  OPTIMIZE_CLAIM_LANGUAGE_REQUEST,
  OPTIMIZE_CLAIM_LANGUAGE_SUCCESS,
  OPTIMIZE_CLAIM_LANGUAGE_FAILURE,
  GET_CLAIM_COMPARISON_REQUEST,
  GET_CLAIM_COMPARISON_SUCCESS,
  GET_CLAIM_COMPARISON_FAILURE,
  GET_POLICY_UPGRADE_SUGGESTIONS_REQUEST,
  GET_POLICY_UPGRADE_SUGGESTIONS_SUCCESS,
  GET_POLICY_UPGRADE_SUGGESTIONS_FAILURE,
  GET_AI_ASSIST_REQUEST,
  GET_AI_ASSIST_SUCCESS,
  GET_AI_ASSIST_FAILURE,
  OPTIMIZE_CLAIM_REQUEST,
  OPTIMIZE_CLAIM_SUCCESS,
  OPTIMIZE_CLAIM_FAILURE,
  PREDICT_RATE_IMPACT_REQUEST,
  PREDICT_RATE_IMPACT_SUCCESS,
  PREDICT_RATE_IMPACT_FAILURE
} from '../actions/types';

const initialState = {
  claims: [],
  draftClaims: [],
  loading: false,
  error: null,
  aiInsights: null,
  documentAnalysis: null,
  optimizedLanguage: null,
  claimComparison: null,
  policyUpgradeSuggestions: null,
  aiAssistFeedback: null,
  optimizedClaim: null,
  rateImpactPrediction: null
};

const claimReducer = (state = initialState, action) => {
  switch (action.type) {
    // Handle loading state for all requests
    case FETCH_CLAIMS_REQUEST:
    case SAVE_DRAFT_CLAIM_REQUEST:
    case UPDATE_DRAFT_CLAIM_REQUEST:
    case DELETE_DRAFT_CLAIM_REQUEST:
    case SUBMIT_CLAIM_REQUEST:
    case GET_AI_INSIGHTS_REQUEST:
    case ANALYZE_DOCUMENT_REQUEST:
    case OPTIMIZE_CLAIM_LANGUAGE_REQUEST:
    case GET_CLAIM_COMPARISON_REQUEST:
    case GET_POLICY_UPGRADE_SUGGESTIONS_REQUEST:
    case GET_AI_ASSIST_REQUEST:
    case OPTIMIZE_CLAIM_REQUEST:
    case PREDICT_RATE_IMPACT_REQUEST:
      return { ...state, loading: true, error: null };

    // Handle success for fetching claims
    case FETCH_CLAIMS_SUCCESS:
      return { ...state, claims: action.payload, loading: false };

    // Handle success for saving draft claim
    case SAVE_DRAFT_CLAIM_SUCCESS:
      return { ...state, draftClaims: [...state.draftClaims, action.payload], loading: false };

    // Handle success for updating draft claim
    case UPDATE_DRAFT_CLAIM_SUCCESS:
      return {
        ...state,
        draftClaims: state.draftClaims.map(claim =>
          claim.id === action.payload.id ? action.payload : claim
        ),
        loading: false
      };

    // Handle success for deleting draft claim
    case DELETE_DRAFT_CLAIM_SUCCESS:
      return {
        ...state,
        draftClaims: state.draftClaims.filter(claim => claim.id !== action.payload),
        loading: false
      };

    // Handle success for submitting a claim
    case SUBMIT_CLAIM_SUCCESS:
      return {
        ...state,
        claims: [...state.claims, action.payload],
        draftClaims: state.draftClaims.filter(claim => claim.id !== action.payload.id),
        loading: false
      };

    // Handle success for AI insights
    case GET_AI_INSIGHTS_SUCCESS:
      return { ...state, aiInsights: action.payload, loading: false };

    // Handle success for document analysis
    case ANALYZE_DOCUMENT_SUCCESS:
      return { ...state, documentAnalysis: action.payload, loading: false };

    // Handle success for optimizing claim language
    case OPTIMIZE_CLAIM_LANGUAGE_SUCCESS:
      return { ...state, optimizedLanguage: action.payload, loading: false };

    // Handle success for claim comparison
    case GET_CLAIM_COMPARISON_SUCCESS:
      return { ...state, claimComparison: action.payload, loading: false };

    // Handle success for policy upgrade suggestions
    case GET_POLICY_UPGRADE_SUGGESTIONS_SUCCESS:
      return { ...state, policyUpgradeSuggestions: action.payload, loading: false };

    // Handle success for AI assist feedback
    case GET_AI_ASSIST_SUCCESS:
      return { ...state, aiAssistFeedback: action.payload, loading: false };

    // Handle success for optimizing claim
    case OPTIMIZE_CLAIM_SUCCESS:
      return { ...state, optimizedClaim: action.payload, loading: false };

    // Handle success for rate impact prediction
    case PREDICT_RATE_IMPACT_SUCCESS:
      return { ...state, rateImpactPrediction: action.payload, loading: false };

    // Handle all failures
    case FETCH_CLAIMS_FAILURE:
    case SAVE_DRAFT_CLAIM_FAILURE:
    case UPDATE_DRAFT_CLAIM_FAILURE:
    case DELETE_DRAFT_CLAIM_FAILURE:
    case SUBMIT_CLAIM_FAILURE:
    case GET_AI_INSIGHTS_FAILURE:
    case ANALYZE_DOCUMENT_FAILURE:
    case OPTIMIZE_CLAIM_LANGUAGE_FAILURE:
    case GET_CLAIM_COMPARISON_FAILURE:
    case GET_POLICY_UPGRADE_SUGGESTIONS_FAILURE:
    case GET_AI_ASSIST_FAILURE:
    case OPTIMIZE_CLAIM_FAILURE:
    case PREDICT_RATE_IMPACT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default claimReducer;
