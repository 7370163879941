import { FETCH_REWARDS, UPDATE_REWARDS, ADD_REWARD_POINTS, UPDATE_BADGE } from '../actions/rewardActions';

const initialState = {
  points: 0,
  badges: [],
};

const rewardReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case FETCH_REWARDS:
    case UPDATE_REWARDS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        points: action.payload.points ?? state.points,
        badges: action.payload.badges ?? state.badges,
      };
    case ADD_REWARD_POINTS:
      return {
        ...state,
        points: (state.points || 0) + (action.payload || 0),
      };
    case UPDATE_BADGE:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        badges: [...(state.badges || []), action.payload],
      };
    default:
      return state;
  }
};

export default rewardReducer;
