import React, { lazy, Suspense, useEffect, useState, useCallback, startTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from './redux/actions/authActions';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import { Analytics } from '@vercel/analytics/react';
import ScrollToTop from './components/ScrollToTop';

// Lazy-loaded components
const Dashboard = lazy(() => import('./components/Dashboard'));
const PoliciesPage = lazy(() => import('./pages/PoliciesPage'));
const AIInsightsPage = lazy(() => import('./pages/AIInsightsPage'));
const DocumentsPage = lazy(() => import('./pages/DocumentsPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const PersonalInfoPage = lazy(() => import('./pages/PersonalInfoPage'));
const Register = lazy(() => import('./components/Register'));
const Login = lazy(() => import('./components/Login'));
const Resources = lazy(() => import('./components/Resources'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const FeaturesPage = lazy(() => import('./pages/FeaturesPage'));
const EnterpriseLandingPage = lazy(() => import('./pages/EnterpriseLandingPage'));
const EnterpriseLandingPage2 = lazy(() => import('./pages/EnterpriseLandingPage2'));

const LandingPage2 = lazy(() => import('./pages/landingpage2'));
const SubscriptionManager = lazy(() => import('./components/SubscriptionManager'));
const EditPolicyPage = lazy(() => import('./pages/EditPolicyPage'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Careers = lazy(() => import('./pages/Careers'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const BlogPage = lazy(() => import('./pages/Blog'));
const FAQ = lazy(() => import('./pages/FAQ'));
const Support = lazy(() => import('./pages/Support'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const CookiePolicy = lazy(() => import('./pages/CookiePolicy'));
const BlogPost = lazy(() => import('./pages/BlogPost'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));

const ClaimsManagementPage = lazy(() => import('./pages/ClaimsManagementPage'));
const SubscriptionSuccess = lazy(() => import('./components/SubscriptionSuccess'));
const PolicyComparison = lazy(() => import('./components/PolicyComparison'));

const Calculator = lazy(() => import('./components/calculator'));
const CignaLoginButton = lazy(() => import('./components/CignaLoginButton'));
const CignaPolicies = lazy(() => import('./pages/CignaPolicies'));
const NotFound = lazy(() => import('./pages/NotFound'));

/* const SECSimulator = lazy(() => import('./components/SECSimulator'));
 */
// PrivateRoute component to protect routes
const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

// ErrorBoundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h2>Something went wrong. Please try again later.</h2>;
    }

    return this.props.children;
  }
}

const App = () => {
  const [isGuidedTour, setIsGuidedTour] = useState(() => !localStorage.getItem('hasSeenTour'));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  const handleRestartTour = useCallback(() => {
    startTransition(() => {
      setIsGuidedTour(true);
      localStorage.removeItem('hasSeenTour');
    });
  }, []);

  const handleTourEnd = useCallback(() => {
    startTransition(() => {
      setIsGuidedTour(false);
      localStorage.setItem('hasSeenTour', 'true');
    });
  }, []);

  useEffect(() => {
    const refreshInterval = setInterval(async () => {
      await refreshToken();
    }, 1000 * 60 * 15); // Refresh every 15 minutes

    return () => clearInterval(refreshInterval);
  }, []);

  return (
    <Layout onRestartTour={handleRestartTour}>
      <ScrollToTop />
      <Suspense>
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<LandingPage2 />} />
            <Route path="/home" element={<LandingPage2 />} />
            <Route path="/home/2" element={<LandingPage />} />
            <Route path="/reddit" element={<LandingPage />} />
            <Route path="/teams" element={<EnterpriseLandingPage />} />
            <Route path="/teams/2" element={<EnterpriseLandingPage2 />} />

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/post/:id" element={<BlogPost />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/support" element={<Support />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/calculator" element={<Calculator />} />
            <Route path="/cigna-policies" element={<CignaPolicies />} />
            <Route path="/cigna-login" element={<CignaLoginButton />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route path="/claims" element={<ClaimsManagementPage />} />
            <Route path="/subscription" element={<SubscriptionSuccess />} />

{/*             <Route path="/sec-simulator" element={<SECSimulator />} />
 */}
            {/* Protected Routes */}
            <Route 
              path="/dashboard" 
              element={
                <PrivateRoute>
                  <Dashboard isGuidedTour={isGuidedTour} onTourEnd={handleTourEnd} />
                </PrivateRoute>
              } 
            />
            
            <Route 
              path="/ai-insights" 
              element={
                <PrivateRoute>
                  <AIInsightsPage />
                </PrivateRoute>
              } 
            />        <Route 
            path="/compare" 
            element={
              <PrivateRoute>
                <PolicyComparison />
              </PrivateRoute>
            } 
          />
            
            <Route 
              path="/documents" 
              element={
                <PrivateRoute>
                  <DocumentsPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/settings" 
              element={
                <PrivateRoute>
                  <SettingsPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/personal-info" 
              element={
                <PrivateRoute>
                  <PersonalInfoPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/policies" 
              element={
                <PrivateRoute>
                  <PoliciesPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/subscribe" 
              element={
                <PrivateRoute>
                  <SubscriptionManager />
                </PrivateRoute>
              } 
            />

            {/* 404 Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
      <Analytics />
    </Layout>
  );
};

export default App;
