import React from 'react';
import styled from 'styled-components';
import { TwitterLogo } from 'phosphor-react';
import { Link } from 'react-router-dom';

const StyledFooter = styled.footer`
  background: rgba(255, 255, 255, 0.98);
  color: #2A2A2A;
  padding: 6rem 2rem 4rem;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  backdrop-filter: blur(8px);

  @media (max-width: 768px) {
    padding: 4rem 1.5rem 3rem;
    text-align: center;
  }
`;

const FooterContainer = styled.div`
  width: 90%;
  max-width: 1070px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(8px);
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 3rem 4rem;
  box-shadow: 
    0px 1px 2px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 8px 16px rgba(0, 0, 0, 0.04);

  @media (max-width: 768px) {
    width: 95%;
    padding: 2rem 1.5rem;
    border-radius: 18px;
  }
`;

const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const SectionTitle = styled.h3`
  color: #2A2A2A;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const FooterLink = styled(Link)`
  color: #2d3748;
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0;

  &:hover {
    color: #003a8c;
    transform: translateX(4px);
  }
`;

const ExternalLink = styled.a`
  color: #2A2A2A;
  font-size: 1rem;
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover {
    color: #0066CC;
    text-decoration: underline;
  }
`;

const DisclaimerText = styled.p`
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  line-height: 1.6;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1.5rem;
  
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2d3748;
  transition: all 0.3s ease;
  padding: 0.75rem;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.03);
  
  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    transform: translateY(-2px);
    color: #003a8c;
    background: rgba(0, 58, 140, 0.05);
  }
`;

const Footer = () => {
  return (
    <StyledFooter role="contentinfo">
      <FooterContainer>
        <FooterGrid>
          <FooterSection>
            <SectionTitle>Company</SectionTitle>
            <FooterLinks>
              <FooterLink to="/about-us">About Us</FooterLink>
              <FooterLink to="/careers">Careers</FooterLink>
              <FooterLink to="/features">Features</FooterLink>
              <FooterLink to="/contact-us">Contact Us</FooterLink>
            </FooterLinks>
          </FooterSection>

          <FooterSection>
            <SectionTitle>Resources</SectionTitle>
            <FooterLinks>
              <FooterLink to="/blog">Blog</FooterLink>
              <FooterLink to="/faq">FAQ</FooterLink>
              <FooterLink to="/support">Support</FooterLink>
            </FooterLinks>
          </FooterSection>

          <FooterSection>
            <SectionTitle>Legal</SectionTitle>
            <FooterLinks>
              <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
              <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
              <FooterLink to="/cookie-policy">Cookie Policy</FooterLink>
            </FooterLinks>
          </FooterSection>

          <FooterSection>
            <SectionTitle>Connect</SectionTitle>
            <SocialLinks>
              <SocialIcon 
                href="https://twitter.com/InsureSmartAI" 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Follow us on Twitter"
              >
                <TwitterLogo size={24} weight="bold" aria-hidden="true" />
              </SocialIcon>
            </SocialLinks>
          </FooterSection>
        </FooterGrid>

        <DisclaimerText>
          © 2024 InsureSmart.ai. All rights reserved. AI recommendations are provided for informational purposes only. 
          We do not guarantee accuracy. We are constantly improving to make the platform better.
        </DisclaimerText>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
