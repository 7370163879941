const loadFonts = async () => {
    if ('fonts' in document) {
      try {
        await Promise.all([
          document.fonts.load('1em Inter'),
          document.fonts.load('italic 1em Inter')
        ]);
        document.documentElement.classList.add('fonts-loaded');
      } catch (err) {
        console.warn('Font loading failed:', err);
        // Fallback to system fonts
        document.documentElement.classList.add('fonts-failed');
      }
    }
  };
  
  export default loadFonts;