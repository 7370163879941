// Policy Action Types
export const UPDATE_POLICY_REQUEST = 'UPDATE_POLICY_REQUEST';
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS';
export const UPDATE_POLICY_FAILURE = 'UPDATE_POLICY_FAILURE';

export const DELETE_POLICY_REQUEST = 'DELETE_POLICY_REQUEST';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';
export const DELETE_POLICY_FAILURE = 'DELETE_POLICY_FAILURE';

export const ANALYZE_POLICY_REQUEST = 'ANALYZE_POLICY_REQUEST';
export const ANALYZE_POLICY_SUCCESS = 'ANALYZE_POLICY_SUCCESS';
export const ANALYZE_POLICY_FAILURE = 'ANALYZE_POLICY_FAILURE';

export const ASK_POLICY_COVERAGE_REQUEST = 'ASK_POLICY_COVERAGE_REQUEST';
export const ASK_POLICY_COVERAGE_SUCCESS = 'ASK_POLICY_COVERAGE_SUCCESS';
export const ASK_POLICY_COVERAGE_FAILURE = 'ASK_POLICY_COVERAGE_FAILURE';

// Personal Info Action Types
export const PERSONAL_INFO_REQUEST = 'PERSONAL_INFO_REQUEST';
export const PERSONAL_INFO_SUCCESS = 'PERSONAL_INFO_SUCCESS';
export const PERSONAL_INFO_FAILURE = 'PERSONAL_INFO_FAILURE';

export const FETCH_PERSONAL_INFO_REQUEST = 'FETCH_PERSONAL_INFO_REQUEST';
export const FETCH_PERSONAL_INFO_SUCCESS = 'FETCH_PERSONAL_INFO_SUCCESS';
export const FETCH_PERSONAL_INFO_FAILURE = 'FETCH_PERSONAL_INFO_FAILURE';

export const UPDATE_PERSONAL_INFO_REQUEST = 'UPDATE_PERSONAL_INFO_REQUEST';
export const UPDATE_PERSONAL_INFO_SUCCESS = 'UPDATE_PERSONAL_INFO_SUCCESS';
export const UPDATE_PERSONAL_INFO_FAILURE = 'UPDATE_PERSONAL_INFO_FAILURE';

// Auth Action Types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

// Policy Management Action Types
export const FETCH_POLICIES_REQUEST = 'FETCH_POLICIES_REQUEST';
export const FETCH_POLICIES_SUCCESS = 'FETCH_POLICIES_SUCCESS';
export const FETCH_POLICIES_FAILURE = 'FETCH_POLICIES_FAILURE';

export const ADD_POLICY_REQUEST = 'ADD_POLICY_REQUEST';
export const ADD_POLICY_SUCCESS = 'ADD_POLICY_SUCCESS';
export const ADD_POLICY_FAILURE = 'ADD_POLICY_FAILURE';

// AI Insights Action Types
export const FETCH_AI_INSIGHTS_REQUEST = 'FETCH_AI_INSIGHTS_REQUEST';
export const FETCH_AI_INSIGHTS_SUCCESS = 'FETCH_AI_INSIGHTS_SUCCESS';
export const FETCH_AI_INSIGHTS_FAILURE = 'FETCH_AI_INSIGHTS_FAILURE';
export const CLEAR_AI_INSIGHTS_CACHE = 'CLEAR_AI_INSIGHTS_CACHE';

// **Add the Missing GET_AI_INSIGHTS Action Types**
export const GET_AI_INSIGHTS_REQUEST = 'GET_AI_INSIGHTS_REQUEST';
export const GET_AI_INSIGHTS_SUCCESS = 'GET_AI_INSIGHTS_SUCCESS';
export const GET_AI_INSIGHTS_FAILURE = 'GET_AI_INSIGHTS_FAILURE';

// Subscription Action Types
export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';

export const GET_SUBSCRIPTION_REQUEST = 'GET_SUBSCRIPTION_REQUEST';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAILURE = 'GET_SUBSCRIPTION_FAILURE';

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';

// Password Reset Action Types
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

// Single Policy Analysis Action Types
export const ANALYZE_SINGLE_POLICY_REQUEST = 'ANALYZE_SINGLE_POLICY_REQUEST';
export const ANALYZE_SINGLE_POLICY_SUCCESS = 'ANALYZE_SINGLE_POLICY_SUCCESS';
export const ANALYZE_SINGLE_POLICY_FAILURE = 'ANALYZE_SINGLE_POLICY_FAILURE';

// Policy Chat Action Types
export const CHAT_WITH_POLICY_REQUEST = 'CHAT_WITH_POLICY_REQUEST';
export const CHAT_WITH_POLICY_SUCCESS = 'CHAT_WITH_POLICY_SUCCESS';
export const CHAT_WITH_POLICY_FAILURE = 'CHAT_WITH_POLICY_FAILURE';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

// Claims Action Types
export const FETCH_CLAIMS_REQUEST = 'FETCH_CLAIMS_REQUEST';
export const FETCH_CLAIMS_SUCCESS = 'FETCH_CLAIMS_SUCCESS';
export const FETCH_CLAIMS_FAILURE = 'FETCH_CLAIMS_FAILURE';

export const SAVE_DRAFT_CLAIM_REQUEST = 'SAVE_DRAFT_CLAIM_REQUEST';
export const SAVE_DRAFT_CLAIM_SUCCESS = 'SAVE_DRAFT_CLAIM_SUCCESS';
export const SAVE_DRAFT_CLAIM_FAILURE = 'SAVE_DRAFT_CLAIM_FAILURE';

export const UPDATE_DRAFT_CLAIM_REQUEST = 'UPDATE_DRAFT_CLAIM_REQUEST';
export const UPDATE_DRAFT_CLAIM_SUCCESS = 'UPDATE_DRAFT_CLAIM_SUCCESS';
export const UPDATE_DRAFT_CLAIM_FAILURE = 'UPDATE_DRAFT_CLAIM_FAILURE';

export const DELETE_DRAFT_CLAIM_REQUEST = 'DELETE_DRAFT_CLAIM_REQUEST';
export const DELETE_DRAFT_CLAIM_SUCCESS = 'DELETE_DRAFT_CLAIM_SUCCESS';
export const DELETE_DRAFT_CLAIM_FAILURE = 'DELETE_DRAFT_CLAIM_FAILURE';

export const SUBMIT_CLAIM_REQUEST = 'SUBMIT_CLAIM_REQUEST';
export const SUBMIT_CLAIM_SUCCESS = 'SUBMIT_CLAIM_SUCCESS';
export const SUBMIT_CLAIM_FAILURE = 'SUBMIT_CLAIM_FAILURE';

// Document Analysis Action Types
export const ANALYZE_DOCUMENT_REQUEST = 'ANALYZE_DOCUMENT_REQUEST';
export const ANALYZE_DOCUMENT_SUCCESS = 'ANALYZE_DOCUMENT_SUCCESS';
export const ANALYZE_DOCUMENT_FAILURE = 'ANALYZE_DOCUMENT_FAILURE';

// Claim Optimization Action Types
export const OPTIMIZE_CLAIM_REQUEST = 'OPTIMIZE_CLAIM_REQUEST';
export const OPTIMIZE_CLAIM_SUCCESS = 'OPTIMIZE_CLAIM_SUCCESS';
export const OPTIMIZE_CLAIM_FAILURE = 'OPTIMIZE_CLAIM_FAILURE';

export const OPTIMIZE_CLAIM_LANGUAGE_REQUEST = 'OPTIMIZE_CLAIM_LANGUAGE_REQUEST';
export const OPTIMIZE_CLAIM_LANGUAGE_SUCCESS = 'OPTIMIZE_CLAIM_LANGUAGE_SUCCESS';
export const OPTIMIZE_CLAIM_LANGUAGE_FAILURE = 'OPTIMIZE_CLAIM_LANGUAGE_FAILURE';

// Claim Comparison Action Types
export const GET_CLAIM_COMPARISON_REQUEST = 'GET_CLAIM_COMPARISON_REQUEST';
export const GET_CLAIM_COMPARISON_SUCCESS = 'GET_CLAIM_COMPARISON_SUCCESS';
export const GET_CLAIM_COMPARISON_FAILURE = 'GET_CLAIM_COMPARISON_FAILURE';

// Policy Upgrade Suggestions Action Types
export const GET_POLICY_UPGRADE_SUGGESTIONS_REQUEST = 'GET_POLICY_UPGRADE_SUGGESTIONS_REQUEST';
export const GET_POLICY_UPGRADE_SUGGESTIONS_SUCCESS = 'GET_POLICY_UPGRADE_SUGGESTIONS_SUCCESS';
export const GET_POLICY_UPGRADE_SUGGESTIONS_FAILURE = 'GET_POLICY_UPGRADE_SUGGESTIONS_FAILURE';

// AI Assist Action Types
export const GET_AI_ASSIST_REQUEST = 'GET_AI_ASSIST_REQUEST';
export const GET_AI_ASSIST_SUCCESS = 'GET_AI_ASSIST_SUCCESS';
export const GET_AI_ASSIST_FAILURE = 'GET_AI_ASSIST_FAILURE';

// Claim AI Assistance Action Types
export const GET_CLAIM_AI_ASSISTANCE_REQUEST = 'GET_CLAIM_AI_ASSISTANCE_REQUEST';
export const GET_CLAIM_AI_ASSISTANCE_SUCCESS = 'GET_CLAIM_AI_ASSISTANCE_SUCCESS';
export const GET_CLAIM_AI_ASSISTANCE_FAILURE = 'GET_CLAIM_AI_ASSISTANCE_FAILURE';
export const CLEAR_POLICY_ANALYSIS_CACHE = 'CLEAR_POLICY_ANALYSIS_CACHE';

// Rate Impact Prediction Action Types
export const PREDICT_RATE_IMPACT_REQUEST = 'PREDICT_RATE_IMPACT_REQUEST';
export const PREDICT_RATE_IMPACT_SUCCESS = 'PREDICT_RATE_IMPACT_SUCCESS';
export const PREDICT_RATE_IMPACT_FAILURE = 'PREDICT_RATE_IMPACT_FAILURE';

// Personalized Education Action Types
export const GET_PERSONALIZED_EDUCATION_REQUEST = 'GET_PERSONALIZED_EDUCATION_REQUEST';
export const GET_PERSONALIZED_EDUCATION_SUCCESS = 'GET_PERSONALIZED_EDUCATION_SUCCESS';
export const GET_PERSONALIZED_EDUCATION_FAILURE = 'GET_PERSONALIZED_EDUCATION_FAILURE';

// Token Refresh Action Types
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';

// Policy Comparison Action Types
export const COMPARE_POLICIES_REQUEST = 'COMPARE_POLICIES_REQUEST';
export const COMPARE_POLICIES_SUCCESS = 'COMPARE_POLICIES_SUCCESS';
export const COMPARE_POLICIES_FAILURE = 'COMPARE_POLICIES_FAILURE';

// Preference Weights Action Types
export const UPDATE_PREFERENCE_WEIGHTS = 'UPDATE_PREFERENCE_WEIGHTS';
export const CLEAR_COMPARISON = 'CLEAR_COMPARISON';

// Policy Validation Action Types
export const VALIDATE_POLICY_REQUEST = 'VALIDATE_POLICY_REQUEST';
export const VALIDATE_POLICY_SUCCESS = 'VALIDATE_POLICY_SUCCESS';
export const VALIDATE_POLICY_FAILURE = 'VALIDATE_POLICY_FAILURE';

// Policy Recommendations Action Types
export const GET_POLICY_RECOMMENDATIONS_REQUEST = 'GET_POLICY_RECOMMENDATIONS_REQUEST';
export const GET_POLICY_RECOMMENDATIONS_SUCCESS = 'GET_POLICY_RECOMMENDATIONS_SUCCESS';
export const GET_POLICY_RECOMMENDATIONS_FAILURE = 'GET_POLICY_RECOMMENDATIONS_FAILURE';

// Summary Generation Action Types
export const GENERATE_SUMMARY_REQUEST = 'GENERATE_SUMMARY_REQUEST';
export const GENERATE_SUMMARY_SUCCESS = 'GENERATE_SUMMARY_SUCCESS';
export const GENERATE_SUMMARY_FAILURE = 'GENERATE_SUMMARY_FAILURE';

// Cache Management Action Types
export const CLEAR_POLICY_CACHE = 'CLEAR_POLICY_CACHE';
export const CLEAR_ANALYSIS_CACHE = 'CLEAR_ANALYSIS_CACHE';
export const CLEAR_RECOMMENDATIONS_CACHE = 'CLEAR_RECOMMENDATIONS_CACHE';

// Policy Optimization Action Types
export const OPTIMIZE_POLICY_REQUEST = 'OPTIMIZE_POLICY_REQUEST';
export const OPTIMIZE_POLICY_SUCCESS = 'OPTIMIZE_POLICY_SUCCESS';
export const OPTIMIZE_POLICY_FAILURE = 'OPTIMIZE_POLICY_FAILURE';

// Rate Impact Analysis Action Types
export const ANALYZE_RATE_IMPACT_REQUEST = 'ANALYZE_RATE_IMPACT_REQUEST';
export const ANALYZE_RATE_IMPACT_SUCCESS = 'ANALYZE_RATE_IMPACT_SUCCESS';
export const ANALYZE_RATE_IMPACT_FAILURE = 'ANALYZE_RATE_IMPACT_FAILURE';

// Session Management Action Types
export const REFRESH_SESSION_REQUEST = 'REFRESH_SESSION_REQUEST';
export const REFRESH_SESSION_SUCCESS = 'REFRESH_SESSION_SUCCESS';
export const REFRESH_SESSION_FAILURE = 'REFRESH_SESSION_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';