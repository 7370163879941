import {
    FETCH_POLICIES_REQUEST,
    FETCH_POLICIES_SUCCESS,
    FETCH_POLICIES_FAILURE,
    ADD_POLICY_REQUEST,
    ADD_POLICY_SUCCESS,
    ADD_POLICY_FAILURE,
    UPDATE_POLICY_REQUEST,
    UPDATE_POLICY_SUCCESS,
    UPDATE_POLICY_FAILURE,
    DELETE_POLICY_REQUEST,
    DELETE_POLICY_SUCCESS,
    DELETE_POLICY_FAILURE,
    ANALYZE_POLICY_REQUEST,
    ANALYZE_POLICY_SUCCESS,
    ANALYZE_POLICY_FAILURE,
    GET_AI_INSIGHTS_REQUEST,
    GET_AI_INSIGHTS_SUCCESS,
    GET_AI_INSIGHTS_FAILURE,
    ASK_POLICY_COVERAGE_REQUEST,
    ASK_POLICY_COVERAGE_SUCCESS,
    ASK_POLICY_COVERAGE_FAILURE,
    ANALYZE_SINGLE_POLICY_REQUEST,
    ANALYZE_SINGLE_POLICY_SUCCESS,
    ANALYZE_SINGLE_POLICY_FAILURE,
    CHAT_WITH_POLICY_REQUEST,
    CHAT_WITH_POLICY_SUCCESS,
    CHAT_WITH_POLICY_FAILURE,
    CLEAR_POLICY_ANALYSIS_CACHE
} from '../actions/types';

const initialState = {
    policies: [],
    loading: false,
    error: null,
    analyzedPolicy: null,
    policyCoverageAnswer: null,
    analysisResult: null,
    singlePolicyAnalysis: null,
    chatHistory: [],
    lastFetchTime: null
};

const policyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POLICIES_REQUEST:
        case ADD_POLICY_REQUEST:
        case UPDATE_POLICY_REQUEST:
        case DELETE_POLICY_REQUEST:
        case ANALYZE_POLICY_REQUEST:
        case ASK_POLICY_COVERAGE_REQUEST:
            return { ...state, loading: true, error: null };

        case FETCH_POLICIES_SUCCESS:
            return { ...state, loading: false, policies: action.payload };

        case ADD_POLICY_SUCCESS:
            return { ...state, loading: false, policies: [...state.policies, action.payload] };

        case ANALYZE_POLICY_SUCCESS:
            return { ...state, loading: false, analyzedPolicy: action.payload };

        case GET_AI_INSIGHTS_SUCCESS:
            return { ...state, loading: false, aiInsights: action.payload };

        case ASK_POLICY_COVERAGE_SUCCESS:
            return { ...state, loading: false, policyCoverageAnswer: action.payload };

        case UPDATE_POLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                policies: state.policies.map(policy =>
                    policy && policy.id === action.payload.id ? action.payload : policy
                ),
            };

        case DELETE_POLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                policies: state.policies.filter(policy => policy && policy.id !== action.payload),
            };

        case ANALYZE_SINGLE_POLICY_SUCCESS:
            return {
                ...state,
                singlePolicyAnalysis: action.payload.analysis,
                lastFetchTime: action.payload.lastFetchTime,
                loading: false,
                error: null
            };

        case CHAT_WITH_POLICY_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                chatHistory: [...state.chatHistory, action.payload] 
            };

        case FETCH_POLICIES_FAILURE:
        case ADD_POLICY_FAILURE:
        case UPDATE_POLICY_FAILURE:
        case DELETE_POLICY_FAILURE:
        case ANALYZE_POLICY_FAILURE:
        case ASK_POLICY_COVERAGE_FAILURE:
        case ANALYZE_SINGLE_POLICY_FAILURE:
        case CHAT_WITH_POLICY_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case CLEAR_POLICY_ANALYSIS_CACHE:
            return {
                ...state,
                singlePolicyAnalysis: null,
                lastFetchTime: null
            };

        default:
            return state;
    }
};

export default policyReducer;
