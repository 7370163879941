import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './reducers/authReducer';
import subscriptionReducer from './reducers/subscriptionReducer';
import policyReducer from './reducers/policyReducer';
import personalInfoReducer from './reducers/personalInfoReducer';
import insuranceReducer from './reducers/insuranceReducer';
import claimReducer from './reducers/claimReducer';
import aiReducer from './reducers/aiReducer';
import userReducer from './reducers/userReducer';
import cignaAuthReducer from './reducers/cignaAuthReducer';
import rewardReducer from './reducers/rewardReducer';


// Combine all the reducers into a single root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  subscription: subscriptionReducer,
  policies: policyReducer,
  personalInfo: personalInfoReducer,
  insurance: insuranceReducer,
  claims: claimReducer,
  ai: aiReducer,
  user: userReducer,
  cignaAuth: cignaAuthReducer,
  rewards: rewardReducer,


});

// Create the Redux store with the combined reducer, middleware, and DevTools extension
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export default store;
