// Header.js

import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Menu, Avatar, Dropdown, Drawer, Badge } from 'antd';
import {
  HomeOutlined,
  FileProtectOutlined,
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
  BarChartOutlined,
  QuestionCircleOutlined,
  MenuOutlined,
  FileTextOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { logout } from '../redux/actions/authActions';
import logo from '../logo512.png';

const { Header: AntHeader } = Layout;

// Styled Components

const StyledHeader = styled(AntHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px; /* Increased padding from 24px to 32px */
  background: #ffffff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 0 20px; /* Adjusted padding for smaller screens */
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  text-decoration: none;

  img {
    width: 60px;
    height: 60px;
    margin-right: 12px;
  }

  span {
    color: #1a1a1a;
    font-size: 20px;
    font-weight: 600;
  }

  &:hover span {
    color: #1890ff;
  }

  @media (max-width: 768px) {
    img {
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }
    span {
      font-size: 16px;
    }
  }
`;

const StyledMenu = styled(Menu)`
  background: transparent;
  border-bottom: none;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-menu-item {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 500;
    margin: 0 16px;
    transition: color 0.3s ease;

    &:hover {
      color: #1890ff;
    }
  }

  .ant-menu-item-selected {
    color: #1890ff;
    border-bottom: 2px solid #1890ff;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  .desktop-only {
    display: flex;
  }

  @media (max-width: 992px) {
    .desktop-only {
      display: none;
    }
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: #1a1a1a;
  font-size: 18px;
  margin-right: 16px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #1890ff;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    margin-right: 12px;
  }
`;

const MobileMenuButton = styled(IconButton)`
  display: none;

  @media (max-width: 992px) {
    display: block;
    margin-right: 0;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    background: #ffffff;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-drawer-title {
    font-size: 20px;
    font-weight: 600;
  }

  .ant-drawer-close {
    color: #1a1a1a;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-menu {
    border-right: none;
  }

  .ant-menu-item {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  @media (max-width: 768px) {
    .ant-drawer-title {
      font-size: 18px;
    }

    .ant-menu-item {
      font-size: 14px;
    }
  }
`;

const UserAvatar = styled(Avatar)`
  background-color: #1890ff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #40a9ff;
  }

  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
  }
`;

// New Styled Component for Home Page Link
const HomePageLink = styled(Link)`
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  margin-right: 16px; /* Added right margin */
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #1890ff;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin-right: 12px; /* Adjusted margin for smaller screens */
  }
`;

// Add this styled component after the other styled components
const BetaTag = styled(Badge)`
  margin-left: 8px;
  
  .ant-badge-count {
    background-color: #1890ff;
    font-size: 10px;
    font-weight: normal;
    padding: 0 6px;
    height: 18px;
    line-height: 18px;
  }
`;

const Header = ({ onRestartTour }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleRestartTour = () => {
    if (onRestartTour) {
      onRestartTour();
    }
  };

  const menuItems = [
    { key: '/dashboard', icon: <HomeOutlined />, label: 'Dashboard' },
    { key: '/policies', icon: <FileProtectOutlined />, label: 'Policies' },
    { key: '/claims', icon: <FileTextOutlined />, label: 'Claims' },
    { key: '/ai-insights', icon: <BarChartOutlined />, label: 'AI Insights' },
    { key: '/personal-info', icon: <UserOutlined />, label: 'Personal Info' },
    { 
      key: '/compare', 
      icon: <SwapOutlined />, 
      label: (
        <span>
          Compare Quotes
          <BetaTag count="BETA" />
        </span>
      ),
    },
  ];

  const userMenu = (
    <Menu>
      <Menu.Item key="profile">
        <Link to="/personal-info">Personal Info</Link>
      </Menu.Item>
      <Menu.Item key="settings">
        <Link to="/settings">Settings</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledHeader>
      <Logo to="/">
        <img src={logo} alt="InsureSmart.ai Logo" />
        <span>InsureSmart.ai</span>
      </Logo>
      {isAuthenticated ? (
        <>
          <StyledMenu
            mode="horizontal"
            selectedKeys={[location.pathname]}
            onClick={({ key }) => navigate(key)}
          >
            {menuItems.map((item) => (
              <Menu.Item key={item.key} icon={item.icon}>
                {item.label}
              </Menu.Item>
            ))}
          </StyledMenu>
          <RightSection>
            <IconButton onClick={handleRestartTour} className="desktop-only">
              <QuestionCircleOutlined />
            </IconButton>
            <Dropdown overlay={userMenu} placement="bottomRight" arrow>
              <UserAvatar icon={<UserOutlined />} />
            </Dropdown>
            <MobileMenuButton onClick={() => setDrawerVisible(true)}>
              <MenuOutlined />
            </MobileMenuButton>
          </RightSection>
          <StyledDrawer
            title="Menu"
            placement="right"
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
            width="250px"
          >
            <Menu
              mode="inline"
              selectedKeys={[location.pathname]}
              onClick={({ key }) => {
                navigate(key);
                setDrawerVisible(false);
              }}
            >
              {menuItems.map((item) => (
                <Menu.Item key={item.key} icon={item.icon}>
                  {typeof item.label === 'string' ? item.label : item.label}
                </Menu.Item>
              ))}
            </Menu>
            <Menu mode="inline" style={{ marginTop: '24px' }}>
              <Menu.Item key="profile" icon={<UserOutlined />}>
                <Link to="/personal-info">Personal Info</Link>
              </Menu.Item>
              <Menu.Item key="settings" icon={<SettingOutlined />}>
                <Link to="/settings">Settings</Link>
              </Menu.Item>
              <Menu.Item
                key="restart-tour"
                icon={<QuestionCircleOutlined />}
                onClick={() => {
                  handleRestartTour();
                  setDrawerVisible(false);
                }}
              >
                Restart Tour
              </Menu.Item>
              <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
                Logout
              </Menu.Item>
            </Menu>
          </StyledDrawer>
        </>
      ) : (
        <RightSection>
          <HomePageLink to="/home">Return to Home Page</HomePageLink>
        </RightSection>
      )}
    </StyledHeader>
  );
};

export default Header;
