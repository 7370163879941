const initialState = {
  recommendations: [],
  loading: false,
  error: null,
  analysis: null,
};

const insuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ANALYZE_INSURANCE_NEEDS_REQUEST':
      return { ...state, loading: true, error: null };
    case 'ANALYZE_INSURANCE_NEEDS_SUCCESS':
      return { 
        ...state, 
        recommendations: action.payload.recommendations || [],
        analysis: action.payload,
        loading: false, 
        error: null 
      };
    case 'ANALYZE_INSURANCE_NEEDS_FAILURE':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default insuranceReducer;