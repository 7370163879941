import { supabase } from '../../supabaseClient';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  REFRESH_TOKEN_SUCCESS
} from './types';

export const login = (email, password) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) throw error;

    const token = data.session.access_token;
    localStorage.setItem('token', token);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { user: data.user, token },
    });

    return { success: true };
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      payload: error.message,
    });
    return { success: false, error: error.message };
  }
};

// Register action
export const register = (userData) => async (dispatch) => {
  dispatch({ type: REGISTER_REQUEST });
  try {
    // Register the user
    const { data, error } = await supabase.auth.signUp({
      email: userData.email,
      password: userData.password,
    });
    
    if (error) throw error;
    
    // If registration is successful, create initial rewards record
    if (data.user) {
      const { error: rewardsError } = await supabase
        .from('user_rewards')
        .insert({
          user_id: data.user.id,
          points: 0,
          badges: [],
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        });

      if (rewardsError) {
        console.error('Error creating initial rewards:', rewardsError);
      }
    }
    
    if (data.user && !data.session) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: data.user, token: null }
      });
      return { success: true, message: 'Please check your email to confirm your account.' };
    } else if (data.session) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { user: data.user, token: data.session.access_token }
      });
      localStorage.setItem('token', data.session.access_token);
      return { success: true };
    }
  } catch (error) {
    dispatch({
      type: REGISTER_FAILURE,
      payload: error.message
    });
    return { success: false, error: error.message };
  }
};

// Logout action
export const logout = () => async (dispatch) => {
  await supabase.auth.signOut();
  dispatch({ type: LOGOUT });
};

// Check authentication status
export const checkAuth = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const { data, error } = await supabase.auth.getUser();
      if (error) {
        console.log('Error getting user or token expired, attempting to refresh...');
        const { data: refreshData, error: refreshError } = await supabase.auth.refreshSession();
        if (refreshError) {
          console.log('Unable to refresh token, logging out...');
          await supabase.auth.signOut();
          localStorage.removeItem('token');
          dispatch({ type: LOGOUT });
        } else if (refreshData && refreshData.session) {
          localStorage.setItem('token', refreshData.session.access_token);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: refreshData.user, token: refreshData.session.access_token }
          });
        }
      } else if (data && data.user) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data.user, token }
        });
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      dispatch({ type: LOGOUT });
    }
  } else {
    dispatch({ type: LOGOUT });
  }
};

// Forgot password action
export const forgotPassword = (email) => async (dispatch) => {
  dispatch({ type: FORGOT_PASSWORD_REQUEST });
  try {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });
    if (error) throw error;

    dispatch({ type: FORGOT_PASSWORD_SUCCESS });
    return { success: true };
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAILURE,
      payload: error.message,
    });
    return { success: false, error: error.message };
  }
};

// Reset password action
export const resetPassword = (accessToken, newPassword) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST });
  try {
    // Set the session with the access token
    const { data, error } = await supabase.auth.updateUser(
      { password: newPassword },
      { accessToken }
    );

    if (error) throw error;

    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.user });
    return { success: true };
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAILURE,
      payload: error.message,
    });
    return { success: false, error: error.message };
  }
};

// Refresh token action
const refreshToken = async () => {
  const { data, error } = await supabase.auth.refreshSession();
  if (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
  return data.session?.access_token;
};

// Updated checkAuthStatus function
export const checkAuthStatus = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    if (token) {
      const { data, error } = await supabase.auth.getUser();
      if (error) {
        console.log('Error getting user or token expired, attempting to refresh...');
        const { data: refreshData, error: refreshError } = await supabase.auth.refreshSession();
        if (refreshError) {
          console.log('Unable to refresh token, logging out...');
          await supabase.auth.signOut();
          localStorage.removeItem('token');
          dispatch({ type: LOGOUT });
        } else if (refreshData && refreshData.session) {
          localStorage.setItem('token', refreshData.session.access_token);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: refreshData.user, token: refreshData.session.access_token }
          });
        }
      } else if (data && data.user) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data.user, token }
        });
      }
    } else {
      dispatch({ type: LOGOUT });
    }
  } catch (error) {
    console.error('Error checking auth status:', error);
    dispatch({ type: LOGOUT });
  }
};

