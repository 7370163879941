// subscriptionReducer.js

import {
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE
} from '../actions/types';

const initialState = {
  subscription: null,
  loading: false,
  error: null
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: false, subscription: action.payload, error: null };
    case GET_SUBSCRIPTION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    // ... other cases ...
    default:
      return state;
  }
};

export default subscriptionReducer;
