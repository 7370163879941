import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import store from './redux/store';
import App from './App';
import './index.css';
import loadFonts from './utils/fontLoader';

loadFonts();

const container = document.getElementById('root');
const root = createRoot(container);

const AppWrapper = process.env.NODE_ENV === 'development' ? React.StrictMode : React.Fragment;

root.render(
  <AppWrapper>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </AppWrapper>
);