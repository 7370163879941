import React from 'react';
import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const { Content } = AntLayout;

const StyledContent = styled(Content)`
  margin-top: ${props => props.$isLandingPage ? '0' : '64px'};
  padding: ${props => props.$isLandingPage ? '0' : '0 50px'};

  @media (max-width: 768px) {
    padding: ${props => props.$isLandingPage ? '0' : '0 16px'};
  }

  @media (max-width: 576px) {
    padding: ${props => props.$isLandingPage ? '0' : '0 8px'};
  }

  @media (max-width: 400px) {
    padding: ${props => props.$isLandingPage ? '0' : '0 2px'}; // Minimum padding for smaller screens
  }
`;

const Layout = ({ children, onRestartTour }) => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/' || location.pathname === '/home' || location.pathname === '/home/2' || location.pathname === '/blog' || location.pathname === '/features' || location.pathname === '/faq' || location.pathname.startsWith('/blog/post/');

  return (
    <AntLayout className="layout" style={{ minHeight: '100vh' }}>
      {!isLandingPage && <Header onRestartTour={onRestartTour} />}
      <StyledContent $isLandingPage={isLandingPage}>
        <main>{children}</main>
      </StyledContent>
      {!isLandingPage && <Footer />}
    </AntLayout>
  );
};

export default Layout;
