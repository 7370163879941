// personalInfoReducer.js
import {
  FETCH_PERSONAL_INFO_REQUEST,
  FETCH_PERSONAL_INFO_SUCCESS,
  FETCH_PERSONAL_INFO_FAILURE,
  UPDATE_PERSONAL_INFO_REQUEST,
  UPDATE_PERSONAL_INFO_SUCCESS,
  UPDATE_PERSONAL_INFO_FAILURE,
} from '../actions/types';

const initialState = {
  personalInfo: null,
  loading: false,
  error: null,
};

const personalInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERSONAL_INFO_REQUEST:
    case UPDATE_PERSONAL_INFO_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_PERSONAL_INFO_SUCCESS:
    case UPDATE_PERSONAL_INFO_SUCCESS:
      return { ...state, loading: false, personalInfo: action.payload, error: null };
    case FETCH_PERSONAL_INFO_FAILURE:
    case UPDATE_PERSONAL_INFO_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default personalInfoReducer;
