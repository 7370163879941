import {
    FETCH_AI_INSIGHTS_REQUEST,
    FETCH_AI_INSIGHTS_SUCCESS,
    FETCH_AI_INSIGHTS_FAILURE,
    CLEAR_AI_INSIGHTS_CACHE,
    COMPARE_POLICIES_REQUEST,
    COMPARE_POLICIES_SUCCESS,
    COMPARE_POLICIES_FAILURE,
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAILURE,
    UPDATE_PREFERENCE_WEIGHTS,
    CLEAR_COMPARISON,
    VALIDATE_POLICY_REQUEST,
    VALIDATE_POLICY_SUCCESS,
    VALIDATE_POLICY_FAILURE
} from '../actions/types';

const CACHE_KEY = 'AI_INSIGHTS_CACHE';

const getCachedInsights = () => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
        const { insights, lastFetchTime } = JSON.parse(cachedData);
        if (Date.now() - lastFetchTime < 3600000000000) { // 1 hour cache
            return { insights, lastFetchTime };
        }
    }
    return null;
};

const cachedData = getCachedInsights();

const initialState = {
    insights: cachedData ? cachedData.insights : null,
    loading: false,
    error: null,
    lastFetchTime: cachedData ? cachedData.lastFetchTime : null,
    analysis: null,
    processedPolicies: [],
    chatHistory: [],
    preferenceWeights: {
        costSensitivity: 7,
        riskTolerance: 5,
        coveragePreference: 6,
        serviceQuality: 6,
        claimProcess: 7,
        financialStrength: 8,
        policyFlexibility: 5,
        digitalExperience: 5
    },
    validationStatus: null,
    validationError: null
};

const aiReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AI_INSIGHTS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_AI_INSIGHTS_SUCCESS:
            return { ...state, insights: action.payload.insights, lastFetchTime: action.payload.lastFetchTime, loading: false, error: null };
        case FETCH_AI_INSIGHTS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case CLEAR_AI_INSIGHTS_CACHE:
            return { ...state, insights: null, lastFetchTime: null };
        case COMPARE_POLICIES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case COMPARE_POLICIES_SUCCESS:
            return {
                ...state,
                loading: false,
                analysis: action.payload.analysis,
                processedPolicies: action.payload.processedPolicies,
                error: null
            };
        case COMPARE_POLICIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case SEND_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                chatHistory: [...state.chatHistory, action.payload],
                error: null
            };
        case SEND_MESSAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case UPDATE_PREFERENCE_WEIGHTS:
            return {
                ...state,
                preferenceWeights: {
                    ...state.preferenceWeights,
                    ...action.payload
                }
            };
        case CLEAR_COMPARISON:
            return {
                ...state,
                analysis: null,
                processedPolicies: [],
                error: null
            };
        case VALIDATE_POLICY_REQUEST:
            return {
                ...state,
                validationStatus: 'pending',
                validationError: null
            };
        case VALIDATE_POLICY_SUCCESS:
            return {
                ...state,
                validationStatus: 'success',
                validationError: null
            };
        case VALIDATE_POLICY_FAILURE:
            return {
                ...state,
                validationStatus: 'error',
                validationError: action.payload
            };
        default:
            return state;
    }
};

export default aiReducer;
